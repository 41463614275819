@import "imports/variables";

* {
  box-sizing: border-box;
  font-size: 100%;
}
.container {

  .start-button {
    background: $primary-button;
    border: none;
    color: $primary-button-font;
    padding: 6px 20px;
    text-decoration: none;
    transition: .5s;
    border-radius: 6px;
    @include buttonsMetaFontStyles;
    margin-top: 10px;

    &:hover {
      background-color: $primary-button-hover;
    }
  }

  .text-center p {
    display: block;
    margin-top: 10px;
  }

  .text-danger.warning {
    margin: 40px 0;
  }

  #warningCheckbox {
    transform: scale(1.5);
    position: relative;
    left: 5px;
  }

  #warningCheckboxLabel {
    font-size: 0.6em;
    font-weight: normal;
  }

  #logInForm label {
    font-weight: normal;
  }

  #email {
    margin-top: 5%;
  }

  @media only screen and (max-width: 767px) {
    .header {
      margin-top: 85px;
    }
  }
}


